import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";

//
const primaryMain = '#1e1e1e' // dark
const primaryLight = "#212575" // metalic-blue(light)
const secondaryMain = '#fff' // white
const successMain = '#F5F5F5' //grayish
const textPrimary = '#000000' //black
const textSecondary = '#878080' //gray

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryMain,
      light: primaryLight,
    },
  },
});
