import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IAirport } from "../../../types";
import { RootState } from "../../store";

/**
 * Airports state declaration
 */
export interface AirportsState {
  airports: IAirport[];
}

/**
 * Initial airports state declaration
 */
const initialState: AirportsState = {
  airports: [],
};

export const fetchAirports = createAsyncThunk(
  "airports/fetchAirports",
  async (token: string, thunkAPI) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_BASE_URL + "/api/Airports",
      config
    );
    return response.data;
  }
);

/**
 * Airports slice combining actions and reducer
 */
export const airportsSlice = createSlice({
  name: "airports",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAirports: (state, action: PayloadAction<IAirport[]>) => {
      state.airports = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAirports.fulfilled, (state, action) => {
      state.airports = action.payload;
    });
  },
});

export const { setAirports } = airportsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAirports = (state: RootState) => state.airports.airports;

export default airportsSlice.reducer;
