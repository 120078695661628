import React, { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AppRoutes from "../routes/routes";
import { BrowserRouter } from "react-router-dom";
import SuspenseFallback from "./SuspenseFallback/SuspenseFallback";
import ErrorPage from "../pages/ErrorPage";
import { compose } from "redux";
import { Provider } from "react-redux";
import { Middlewares } from "../middleware";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "../utils/theme";
import store from "../store/store";
import "../App.css";

// Added by Fernando B silva
import "bootstrap/dist/css/bootstrap.min.css";
import { NavigationBar } from "./NavigationBar/NavigationBar";
import Footer from "./Footer/Footer";

//Redux DevTools for chrome extension do not delete
declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store =
//   window.location.hostname === "localhost:3000"
//     ? createStore(rootReducer, applyMiddleware(thunk))
//     : createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

interface AppProps { }
const App: React.FC<AppProps> = ({ }) => {
	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<Provider store={store}>
				<BrowserRouter>
					<Suspense fallback={<SuspenseFallback />}>
						<Middlewares>
							<ThemeProvider theme={theme}>
								<div id="header"></div>
								<div id="content">
									<AppRoutes />
								</div>
							</ThemeProvider>
						</Middlewares>
					</Suspense>
				</BrowserRouter>
			</Provider>
		</ErrorBoundary>
	);
};
export default App;
