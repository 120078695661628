import { SurfaceTypeEnum } from "../types";
export const registerAccount = "/registerAccount";
export const errorPermission = "/errorPermission";
export const errorPermission_2 = "/errorPermission/2";
export const networkError = "/networkError";
export const homePage = process.env.REACT_APP_FRONTEND_BASE_URL + "/home";
export const newForm = process.env.REACT_APP_FRONTEND_BASE_URL + "/crane";
export const addUser = process.env.REACT_APP_FRONTEND_BASE_URL + "/addUser";
export const PostHistoryDelete = "Deleted file -  ";
export const PostHistoryUpload = "Uploaded file -  ";
export const PostHistoryAppDraftSave = "Application saved as a DRAFT";
export const PostHistoryAppSubmit = "Application submitted";
export const PostHistoryChangeState = "Application's status changed from ";
export const PostHistoryEditable = "Application's editability status changed from ";
export const PostHistoryOverwriteUpload = "Updated file - ";
export const PostHistoryGlobalOrAdminDelete = "Permanently Deleted -";
export const maxFileSize = 10000000;
export const maxMailKitMessageSize = 134217728;

export enum FormStates {
	"DRAFT" = 0,
	"PAYMENT_PENDING" = 1,
	"SUBMITTED" = 2,
	"PROCESSING" = 3,
	"COMPLETED" = 4,
	"CANCELED" = 5,
	"REJECTED" = 6,
	"APPROVED" = 7,
	"REVOKED" = 8,
}

export enum ApplicationOperatingTimes {
	"START_TIME",
	"FINISH_TIME",
}

export enum TimeFormats {
	"LOCAL TIME",
	"UTC",
}

export enum NotificationTypes {
	"NEW_COMMENT" = 0,
}

export enum CraneTypes {
	"MOBILE CRANE" = 0,
	"TOWER CRANE" = 1,
	"ELEVATED PLATFORM" = 2,
}

export enum surfaceTypes {
	"ols" = 0,
	"anef" = 1,
	"cns" = 2,
	"future_ols" = 3,
	"helicopter" = 4,
	"lighting" = 5,
	"vss" = 6,
	"psa" = 7,
	"wildlife" = 8,
	"wind_farms" = 9,
	"windshear" = 10,
	"pansops" = 11,
}

export enum TransactionResultTypes {
	"SUCCEEDED" = 0,
	"CANCELED" = 1,
	"FAILED" = 2,
}

export enum CraneApplicationLightingTypes {
	"NONE" = 0,
	"FLASHING_RED" = 1,
	"FLASHING WHITE" = 2,
	"STEADY RED" = 3,
	"OTHER" = 4,
}

export enum organisationType {
	"global" = 0,
	"airport" = 1,
	"organisation" = 2,
}

export enum userPermissionType {
	"administrator" = 0,
	"manager" = 1,
	"read_only" = 2,
}

export const surfaceTypeArray = Object.keys(surfaceTypes).slice(Object.keys(surfaceTypes).length / 2);

export const LightingTypeArray = Object.keys(CraneApplicationLightingTypes).slice(Object.keys(CraneApplicationLightingTypes).length / 2);

// index is SurfaceTypeEnum value, value is the number of dimensions (2d/3d)
export const surfaceDimensions: { [id: string]: number } = {
	ols: 3,
	anef: 2,
	cns: 3,
	future_ols: 3,
	helicopter: 3,
	lighting: 2,
	vss: 3,
	psa: 2,
	wildlife: 2,
	wind_farms: 2,
	windshear: 3,
	pansops: 3,
};
