import React from "react";
import Auth0Middleware from "./Auth0Middleware";
import AuthenticationMiddleware from "./AuthenticationMiddleware";

interface MiddlewaresProps { }

const Middlewares: React.FC<MiddlewaresProps> = ({ children }) => {
  return (
    <>
      <Auth0Middleware>
        <AuthenticationMiddleware>
          {children}
        </AuthenticationMiddleware>
      </Auth0Middleware>
    </>
  );
};

export default Middlewares;
