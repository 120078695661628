import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../../App.css";
import logo from "../../images/fullLogo.png";

const useStyles = makeStyles(() => ({
	footerContainer: {
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		color: "white",
	},
	text: {
		color: "white",
		textDecoration: "none",
		padding: "10px",
	},
}));

const Spinner = ({ content }: any) => {
	return (
		<div>
			<div className="d-flex justify-content-center mb-4">
				<img className="mt-2" src={logo} alt="Airspace Lab" />
			</div>
			<div className="d-flex justify-content-center">
				<div className="spinner-border text-info" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
				<div>
					<h3 className="ms-3 pb-1">Loading...</h3>
				</div>
			</div>
		</div>
	);
};

export default Spinner;
