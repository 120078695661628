import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import QueryString from "qs";
import { ISurfaceFeature } from "../../../types";
import { RootState } from "../../store";
import qs from "qs";

/**
 * Surface features state declaration
 */
export interface SurfaceFeaturesState {
  surfaceFeaturesCache: { [id: number]: ISurfaceFeature };
  surfaceFeatures: { [id: number]: ISurfaceFeature };
  surfaceFeaturesFetched: boolean;
}

/**
 * Initial suface features state definition
 */
const initialState: SurfaceFeaturesState = {
  surfaceFeaturesCache: {},
  surfaceFeatures: {},
  surfaceFeaturesFetched: true,
};

interface AddSurfaceFeaturePayload {
  id: number;
  surfaceFeature: ISurfaceFeature;
}

export const fetchSurfaceFeatures = createAsyncThunk(
  "surfaceFeatures/fetchSurfaceFeature",
  async (
    { surfaceIds, token }: { surfaceIds: number[]; token: string },
    thunkAPI
  ) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let state = thunkAPI.getState() as RootState;
    thunkAPI.dispatch(setSurfaceFeaturesFetched(false));
    let surfaceFeatures: { [id: number]: ISurfaceFeature } = {};
    for (let i = 0; i < surfaceIds.length; i++) {
      let id = surfaceIds[i];
      if (id in state.surfaceFeatures.surfaceFeaturesCache) {
        surfaceFeatures[id] = state.surfaceFeatures.surfaceFeaturesCache[id];
      } else {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_BASE_URL +
            `/api/Surfaces/${id}/Feature`,
          config
        );
        surfaceFeatures[id] = response.data;
      }
    }
    return surfaceFeatures;
  }
);

/**
 * Surface feature slice combining actions and reducer
 */
export const surfaceFeaturesSlice = createSlice({
  name: "surfaceFeatures",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addSurfaceFeature: (
      state,
      action: PayloadAction<AddSurfaceFeaturePayload>
    ) => {
      state.surfaceFeaturesCache[action.payload.id] =
        action.payload.surfaceFeature;
    },
    setSurfaceFeaturesFetched: (state, action: PayloadAction<boolean>) => {
      state.surfaceFeaturesFetched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSurfaceFeatures.fulfilled, (state, action) => {
      for (let id in action.payload) {
        state.surfaceFeaturesCache[id] = action.payload[id];
      }
      state.surfaceFeatures = action.payload;
      state.surfaceFeaturesFetched = true;
    });
  },
});

export const { addSurfaceFeature, setSurfaceFeaturesFetched } =
  surfaceFeaturesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSurfaceFeatures = (state: RootState) =>
  state.surfaceFeatures.surfaceFeaturesCache;

export default surfaceFeaturesSlice.reducer;
