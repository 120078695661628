import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { string } from "yup/lib/locale";

interface Auth0MiddlewareProps {}

const Auth0Middleware: React.FC<Auth0MiddlewareProps> = ({ children }) => {
  // Code added by Fernando Silva 14/10/2021
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin + "/home"}
        onRedirectCallback={onRedirectCallback}
        audience="airspacelab-backend-services"
        scope="openid profile email rw:jobs"
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        {/* Components using Auth0 */}
        {children}
      </Auth0Provider>
    </>
  );
};

export default Auth0Middleware;
