import { useSelector } from "react-redux";
import { RootState } from "../___store";
import React from "react";
import Spinner from "./../components/Spinner/Spinner";
import {
  IAccessControl,
  IAirportAPIValues,
  ICraneFormValues,
  ICraneLocation,
  ILocation,
  IUserPermissions,
} from "./interfaces";
import { DialogActions } from "@material-ui/core";
import ConstantPositionProperty from "cesium/Source/DataSources/ConstantPositionProperty";
import { valueToObjectRepresentation } from "@apollo/client/utilities";
import { cpuUsage } from "process";
import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { ISurfaceFeature } from "../___store/surfaceFeatures/types";
import {
  Cartesian3,
  Ion,
  Viewer as ViewerComponent,
  viewerCesiumInspectorMixin,
} from "cesium";
import {
  CameraFlyTo,
  GeoJsonDataSource,
  Viewer,
  CesiumMovementEvent,
  CesiumComponentRef,
  NightVisionStage,
} from "resium";
import earcut from "earcut";
import SpinnerWithoutLogo from "../components/Spinner/SpinnerWithoutLogo";
import { urlFileStorage } from "../api/apiCalls";
import { organisationType, TimeFormats } from "./constantVariables";
import { useHistory } from "react-router-dom";
import { ISurfaceSet } from "../types";
import { copyFileSync } from "fs";

export async function azureUpload(sas: any, blob: Blob) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  return await blockBlobClient.uploadData(blob);
}

export async function azureDownload(sas: any) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  const response = await blockBlobClient.download();
  return response.blobBody;
}

export async function azureDelete(sas: any) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  const response = await blockBlobClient.deleteIfExists();
  return response;
}

export function getDocumentEndpoint(
  appId: number,
  type: number,
  dateTime: string,
  isAttachment: boolean
) {
  const my_uri = `${urlFileStorage}/document/${appId}/${type}/${dateTime}/${isAttachment}`;
  return my_uri;
}

export function getEmailSignatureEndpoint(type: number, orgId: number) {
  const my_uri = `${urlFileStorage}/document/emailSignature/${type}/${orgId}`;
  return my_uri;
}

//Returns a files name formatted with date time before extension
export function formatFileName(file: any) {
  let dateFormat = new Date(file.uploadedDateTime).toLocaleDateString("en-GB");
  let newTime = formatUTC(file.uploadedDateTime);
  let finalString = dateFormat.replaceAll("/", "-") + " " + newTime;
  let formattedFileName: string = file.filename.substring(
    0,
    file.filename.lastIndexOf(".")
  );
  let fileExtension = file.filename.substring(
    file.filename.lastIndexOf("."),
    file.filename.length
  );
  let returnString = formattedFileName + " " + finalString + fileExtension;

  return returnString;
}

export function formatUTC(fileUploaded: string) {
  let timeInt = parseFloat(
    new Date(fileUploaded).toTimeString().substring(0, 3)
  );
  let restOfString = new Date(fileUploaded).toTimeString().substring(2, 8);
  let suffix = timeInt >= 12 ? "PM" : "AM";
  let hours = ((timeInt + 11) % 12) + 1;
  let newTime = hours + restOfString + " " + suffix;
  return newTime;
}

export function formatUTCDateTime(file: any) {
  let time = formatUTC(file.uploadedDateTime);
  let dateformat = new Date(file.uploadedDateTime).toLocaleDateString();
  let finalString = dateformat.replaceAll("/", "-") + " " + time;
  return finalString;
}

// Returns the token of the logged user
export function GetUserToken() {
  const token: string = useSelector(
    (state: RootState) => state.authentication.accessToken
  );
  return token;
}

//returns the time lapsed between 2 events
export function getTimeLapsed(time1: number, time2: number) {}

// Returns the width of the browse window in px
export function GetWindowWidth() {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return width;
}

// Returns the height of the browse window in px
export function GetWindowHeight() {
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return height;
}

// Returns the name of the airport
export function GetAirportName(
  airportId: number | undefined,
  airportArray: Array<any>
) {
  let airportName = "";
  airportArray.forEach((airport) => {
    if (Number(airport.id) === Number(airportId)) {
      airportName = airport.name + " - (" + airport.icao + ")";
    }
  });
  return airportName.toUpperCase();
}

// Returns the name of the airport
export function GetAirportActive(
  airportId: number | undefined,
  airportArray: Array<any>
) {
  let airporActive: boolean = false;
  airportArray.forEach((airport) => {
    if (Number(airport.id) === Number(airportId)) {
      airporActive = airport.active;
    }
  });
  return airporActive;
}

//Chucks array into n sized pieces
export function chunk<T>(array: T[], chunkSize: number): T[][] {
  const R = [];
  for (let i = 0, len = array.length; i < len; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
}

// Returns the name of an Airport Type id
export function GetAirportType(airportType: number) {
  if (airportType === 0) return "CERTIFIED";
  if (airportType === 1) return "AIRCRAFT LANDING AREAS";
  if (airportType === 2) return "MILITARY";
  return "";
}

// Returns Object array with all Airport types
export function GetAllAirportTypes() {
  const types = [
    { key: 0, label: "CERTIFIED" },
    { key: 1, label: "AIRCRAFT LANDING AREAS" },
    { key: 2, label: "MILITARY" },
  ];
  return types;
}

//Return a formatted version of a surface subset (e.g. /{runwayId}/inner_horizontal === INNER_HORIZONTAL)
export function FormatSurfaceSubset(surfaceName: string) {
  if (surfaceName !== "N/A") {
    let finalString = "";
    let stringArray = surfaceName.split("/");
    if (stringArray.length === 2) {
      finalString = stringArray[0] + "  " + stringArray[1].toUpperCase();
    } else {
      finalString =
        stringArray[stringArray.length - 2] +
        "  " +
        stringArray[stringArray.length - 1];
    }
    return finalString;
  } else {
    return surfaceName;
  }
}

// Returns a new data array according to the "pageNumber" and "pageSize" parameters
export function Paginate(
  data: Array<any>,
  pageNumber: number,
  pageSize: number
) {
  const startIndex = (pageNumber - 1) * pageSize;
  const dataPaginated = [];
  for (let i = startIndex; i < startIndex + pageSize; i++) {
    if (data[i] !== undefined) {
      dataPaginated.push(data[i]);
    }
  }
  return dataPaginated;
}

// Temporary function to define the application type
export function GetApplicationType(application: any) {
  let applicationType = "";
  if (application.craneDetail !== null) {
    applicationType = "Crane Application";
  }
  return applicationType;
}

// Validate latitude number
export function IsLatitudeValid(value: string | undefined) {
  if (value === "" || value === undefined) {
    return false;
  }
  var latitude = Number(value);
  if (latitude >= -90 && latitude <= 90) {
    return true;
  }
  return false;
}

// Validate longitude number
export function IsLogitudeValid(value: string | undefined) {
  if (value === "" || value === undefined) {
    return false;
  }
  var latitude = Number(value);
  if (latitude >= -180 && latitude <= 180) {
    return true;
  }
  return false;
}

// Get Arport selected location
export function GetAirportLocation(
  airportId: number,
  airports: IAirportAPIValues[]
) {
  let latitude = 0;
  let longitude = 0;
  airports.map((airport) => {
    if (Number(airport.id) === Number(airportId)) {
      latitude = Number(airport.arp.coordinates[1]);
      longitude = Number(airport.arp.coordinates[0]);
    }
  });

  return {
    latitude: latitude,
    longitude: longitude,
  };
}

// Validate location entered by the applicant
export function IsLocationEnteredValid(
  address: string | undefined,
  latitude: string | undefined,
  longitude: string | undefined,
  useLatLong: boolean
) {
  let message = "Location entered is valid";
  let result = true;

  if (!useLatLong) {
    if (!address || address.length < 3) {
      result = false;
      let message = "Address must have minimum 3 letters";
      return { result: result, message: message };
    }
  }
  if (useLatLong && latitude && longitude) {
    if (!IsLatitudeValid(latitude)) {
      result = false;
      message = "The latitude entered is not valid.";
      return { result: result, message: message };
    }
    if (!IsLogitudeValid(longitude)) {
      result = false;
      message = "The longitude entered is not valid.";
      return { result: result, message: message };
    }
  }
  if (useLatLong && (!latitude || !longitude)) {
    result = false;
    message = "Please enter Latitude and Longitude information.";
    return { result: result, message: message };
  }
  return { result: result, message: message };
}

// Validate return address
export function ValidateAddress(address: string | undefined) {
  if (!address) return false;
  if (!address.includes(",")) return false;
  let values = address.split(",");
  //console.log("Values: ", values);
  if (values.length > 2) return true;
  return false;
}

// Returns network error message
export function ShowNetworkErrorMessage(
  refreshButton: boolean = false,
  path: string = ""
) {
  const history = useHistory();
  const refreshPage = (path: string) => {
    history.push(path);
  };
  return (
    <React.Fragment>
      <div className="alert alert-warning text-center bg-alert" role="alert">
        <div>
          {" "}
          <h4>
            <i className="fas fa-exclamation-triangle fa-1x"></i> The server is
            not reachable
          </h4>
        </div>
        <div className="mt-2">
          {!refreshButton && <p>Please try again later.</p>}
          {refreshButton && (
            <p>
              Check your network connection and click in the button below to try
              again.
            </p>
          )}
        </div>

        {refreshButton && path !== "" && (
          <div className="mt-5">
            <button
              type="button"
              className="btn btn-secondary btn-sm confirmBtn ms-2"
              aria-label="Close"
              onClick={() => refreshPage(path)}
            >
              Refresh page
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

// Returns network error message Server is not available
export function ShowServerIsNotAvailable() {
  return (
    <div className="border-radius p-2 mt-2 bg-alert">
      <div className="d-flex justify-content-center">
        <div className="ms-3">
          <h6 className="pt-1">
            <i className="fas fa-exclamation-triangle fa-1x "></i> The server is
            not reachable
          </h6>
        </div>
      </div>
    </div>
  );
}

// Returns loading spinner
export function ShowLoading() {
  return (
    <React.Fragment>
      <div className="mt-5">
        <Spinner />
      </div>
    </React.Fragment>
  );
}

export function ShowLoadingWithoutLogo(message: string = "") {
  return (
    <React.Fragment>
      <div className="mt-5">
        <SpinnerWithoutLogo message={message} />
      </div>
    </React.Fragment>
  );
}
//-------------------------------------------------------------------------- DATE FORMAT FUNCTIONS -----------------------------------------------//
// Returns a string on the format (YYYY-MM-DD => Exam: 2021-09-01)
export function DateToString(date: Date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let dateString = `${year}-${month <= 9 ? "0" + month : month}-${
    day <= 9 ? "0" + day : day
  }`;
  return dateString;
}

//reformats string from YYYY-MM-DD to DD/MM/YYYY
export function ReFormatDateStringDayFirst(date: string) {
  let dateStringDay = date.substring(date.lastIndexOf("/") + 1, date.length);
  let dateStringYear = date.substring(0, date.indexOf("/"));
  let dateStringMonth = date.substring(
    date.indexOf("/") + 1,
    date.lastIndexOf("/")
  );
  return dateStringDay + "/" + dateStringMonth + "/" + dateStringYear;
}

export function ReFomratDateStringYearFirst(date: string) {
  let dateStringYear = date.substring(date.lastIndexOf("/") + 1, date.length);
  let dateStringDay = date.substring(0, date.indexOf("/"));
  let dateStringMonth = date.substring(
    date.indexOf("/") + 1,
    date.lastIndexOf("/")
  );

  return dateStringYear + "/" + dateStringMonth + "/" + dateStringDay;
}

// Returns a string on the format (YYYY-MM-DD => Exam: 2021-09-01)
export function DateTimeToString(date: Date, withTime: boolean = true) {
  let day = date.getUTCDate();
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();
  let hour = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let seconds = date.getUTCSeconds();
  let dateString;
  if (withTime) {
    dateString = `${year}-${month <= 9 ? "0" + month : month}-${
      day <= 9 ? "0" + day : day
    }-${hour <= 9 ? "0" + hour : hour}:${
      minutes <= 9 ? "0" + minutes : minutes
    }:${seconds <= 9 ? "0" + seconds : seconds}`;
  } else {
    dateString = `${year}-${month <= 9 ? "0" + month : month}-${
      day <= 9 ? "0" + day : day
    }`;
  }
  return dateString;
}

// Returns a string on the format (DD/MM/YYYY => Exam: 30/09/2021)
export function FormatDate(date: string | any) {
  let result;
  if (date !== undefined) {
    result = new Date(date);
    result = FormatDateOptions(result);
  }
  return result;
}

export function FormatDateTime(date: Date) {
  if (date !== undefined) {
    let dateConverted = new Date(date);
    let timezoneOffsetinMS = dateConverted.getTimezoneOffset() * 60000;

    dateConverted.setTime(dateConverted.getTime() - timezoneOffsetinMS);
    let time = dateConverted.toLocaleString().replace(" ", "").split(",")[1];
    let result = `${FormatDateOptions(new Date(date))} - ${time} `;
    return result;
  }
  return null;
}

export function FormatDateTimeUTCFormat(date: Date) {
  if (date !== undefined) {
    let dateConverted = new Date(date);
    dateConverted.setTime(dateConverted.getTime());
    let time = dateConverted.toLocaleString().replace(" ", "").split(",")[1];
    let result = `${FormatDateOptions(new Date(date))} - ${time} `;
    return result;
  }
  return null;
}

// Forta Date to en-AU
export enum DateFormatOptions {
  enAU = "en-AU",
  enUS = "en-US",
}

export enum FormatType {
  ddmmyyyy = 1,
  yyyymmdd = 2,
}
// Function to format the date
export function FormatDateOptions(
  date: Date,
  localTime: DateFormatOptions = DateFormatOptions.enAU,
  type: FormatType = FormatType.ddmmyyyy
) {
  if (type === 1) {
    // DD/MM/YYYY
    return new Date(date).toLocaleDateString(localTime);
  }
  if (type === 2) {
    // YYYY-MM-DD
    let newDate = new Date(date).toLocaleDateString(localTime);
    let dateArray = newDate.split("/");
    newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    return newDate;
  }
}

//function which takes in a date and time and produces a conversion to local or utc time based on the format passed.
//e.g. 28/10/2022 - 10:00:00 pm -> 01/03/2022 - 08:00:00am
//NOTE: input date format must be in DD-MM-YYYY
export function FormatTimeAndDate(
  date: string,
  time: string | null,
  timeFormat: TimeFormats
) {
  let timeResult = "";
  let dateResult = "";

  if (time !== null) {
    //assumed the input date is in utc time -> converts to localTime
    if (timeFormat === 0) {
      let dateStringYear = date.substring(
        date.lastIndexOf("/") + 1,
        date.length
      );
      let dateStringDay = date.substring(0, date.indexOf("/"));
      let dateStringMonth = date.substring(
        date.indexOf("/") + 1,
        date.lastIndexOf("/")
      );
      let dateStringFinal =
        dateStringYear + "-" + dateStringMonth + "-" + dateStringDay;
      let finalDateTimeString = dateStringFinal + "T" + time;

      let utcTime = new Date(finalDateTimeString);
      let localTime = new Date(
        utcTime.getTime() - utcTime.getTimezoneOffset() * 60 * 1000
      );

      timeResult = localTime.toLocaleTimeString();
      dateResult = localTime.toLocaleDateString(DateFormatOptions.enAU);

      return [dateResult, timeResult];
    }

    //assumed the input date is in local time -> converts to UTC
    else {
      let dateStringYear = date.substring(
        date.lastIndexOf("/") + 1,
        date.length
      );
      let dateStringDay = date.substring(0, date.indexOf("/"));
      let dateStringMonth = date.substring(
        date.indexOf("/") + 1,
        date.lastIndexOf("/")
      );
      let dateStringFinal =
        dateStringYear + "-" + dateStringMonth + "-" + dateStringDay;
      let finalDateTimeString = dateStringFinal + "T" + time;
      let localTime = new Date(finalDateTimeString);

      let utcString = localTime.toUTCString();
      let utcDate = localTime.getUTCDate().toString();
      let utcMonth = localTime.getUTCMonth().toString();
      let utcYear = localTime.getUTCFullYear().toString();
      let finishIndexOfTime = new Date(finalDateTimeString)
        .toUTCString()
        .indexOf("G");
      let finishUtcTime = new Date(finalDateTimeString)
        .toUTCString()
        .substring(finishIndexOfTime - 9, finishIndexOfTime - 1);

      utcMonth = (parseFloat(utcMonth) + 1).toString();

      if (parseFloat(utcMonth) < 10) {
        utcMonth = "0" + utcMonth;
      }

      if (parseFloat(utcDate) < 10) {
        utcDate = "0" + utcDate;
      }

      let fullFinishTimeUTCString = utcYear + "-" + utcMonth + "-" + utcDate;

      return [fullFinishTimeUTCString, finishUtcTime];
    }
  } else {
    return [date, undefined];
  }
}

//-------------------------------------------------------------------------- EWAY PAYMENT FUNCTIONS -----------------------------------------------//
export function FormatEwayPaymentValue(value: number) {
  if (value.toString().length > 4) {
    let stringValue = value.toString();
    let elements = stringValue.split(".");

    let combined = elements[0] + elements[1];

    return parseInt(combined);
  } else if (value.toString().length === 4) {
    let stringValue = value.toString();
    let elements = stringValue.split(".");

    let combined = elements[0] + elements[1] + "0";

    return parseInt(combined);
  } else {
    let stringValue = value.toString() + "00";
    return parseInt(stringValue);
  }
}

//returns a formatted string containg a user's firt and last name (e.g. "John Smith")

// Returns the status of the application
export function GetApplicationStatus(state: string | undefined) {
  let result = "";
  if (state !== undefined) {
    if (Number(state) === 0) return "Draft";
    if (Number(state) === 1) return "Pending Payment";
    if (Number(state) === 2) return "Submitted";
    if (Number(state) === 3) return "Processing";
    if (Number(state) === 4) return "Completed";
    if (Number(state) === 5) return "Cancelled";
    if (Number(state) === 6) return "Rejected";
    if (Number(state) === 7) return "Approved";
  }
  return result;
}

// Take as paramente the organisation information string and returns an object
export function GetABNObject(data: any) {
  let result = null;
  try {
    let splitArray = data.slice(9);
    let capitalisedArray = splitArray.replace(")", "");
    let lowerCaseArray = capitalisedArray.toLowerCase();
    result = JSON.parse(lowerCaseArray);
    return result;
  } catch {
    return result;
  }
}

// Returns true if there are any differences between the two objects
export function HasCraneApplicationChanged(
  obj1: ICraneFormValues,
  obj2: ICraneFormValues
) {
  if (obj1.airportId !== obj2.airportId) {
    return true;
  }
  if (obj1.id !== obj2.id) {
    return true;
  }
  if (obj1.location?.coordinates[0] !== obj2.location?.coordinates[0]) {
    return true;
  }
  if (obj1.location?.coordinates[1] !== obj2.location?.coordinates[1]) {
    return true;
  }
  if (obj1.craneDetails.type !== obj2.craneDetails.type) {
    return true;
  }
  if (obj1.craneDetails.model !== obj2.craneDetails.model) {
    return true;
  }
  if (obj1.craneDetails.height !== obj2.craneDetails.height) {
    return true;
  }
  if (
    obj1.craneDetails.boomColourScheme !== obj2.craneDetails.boomColourScheme
  ) {
    return true;
  }
  if (obj1.craneDetails.boomColours !== obj2.craneDetails.boomColours) {
    return true;
  }
  if (obj1.craneDetails.craneLighting !== obj2.craneDetails.craneLighting) {
    return true;
  }
  if (
    obj1.locations[0]?.maximumGroundLevel !==
    obj2.locations[0]?.maximumGroundLevel
  ) {
    return true;
  }
  if (
    obj1.locations[0]?.maximumOperatingHeightAGL !==
    obj2.locations[0]?.maximumOperatingHeightAGL
  ) {
    return true;
  }
  if (
    obj1.locations[0]?.maximumOperatingHeight !==
    obj2.locations[0]?.maximumOperatingHeight
  ) {
    return true;
  }
  if (
    obj1.craneOperationParameters.operatingStartDate !==
    obj2.craneOperationParameters.operatingStartDate
  ) {
    return true;
  }
  if (
    obj1.craneOperationParameters.operatingFinishDate !==
    obj2.craneOperationParameters.operatingFinishDate
  ) {
    return true;
  }
  if (
    obj1.craneOperationParameters.dailyStartTime !==
    obj2.craneOperationParameters.dailyStartTime
  ) {
    return true;
  }
  if (
    obj1.craneOperationParameters.dailyFinishTime !==
    obj2.craneOperationParameters.dailyFinishTime
  ) {
    return true;
  }
  if (
    obj1.craneOperationParameters.craneOperatorName !==
    obj2.craneOperationParameters.craneOperatorName
  ) {
    return true;
  }
  if (
    obj1.craneOperationParameters.craneOperatorMobile !==
    obj2.craneOperationParameters.craneOperatorMobile
  ) {
    return true;
  }
  // New added by FBS
  if (obj1.companyName !== obj2.companyName) {
    return true;
  }
  if (obj1.legacy !== obj2.legacy) {
    return true;
  }
  if (obj1.addressWorksite !== obj2.addressWorksite) {
    return true;
  }
  if (obj1.crossStreet !== obj2.crossStreet) {
    return true;
  }
  if (obj1.activityType !== obj2.activityType) {
    return true;
  }
  if (obj1.assessmentNumber !== obj2.assessmentNumber) {
    return true;
  }

  return false;
}

// Returns true if there are any differences between the two objects
export function FindCraneApplicationValuesChanged(
  obj1: ICraneFormValues,
  obj2: ICraneFormValues
) {
  let values: any[][] = [];

  if (obj1.airportId !== obj2.airportId) {
    let array = ["airportId", obj1.airportId, obj2.airportId];
    values.push(array);
  }
  if (obj1.id !== obj2.id) {
    let array = ["id", obj1.id, obj2.id];
    values.push(array);
  }
  if (obj1.location?.coordinates[0] !== obj2.location?.coordinates[0]) {
    let array = [
      "longitude",
      obj1.location?.coordinates[0],
      obj2.location?.coordinates[0],
    ];
    values.push(array);
  }
  if (obj1.location?.coordinates[1] !== obj2.location?.coordinates[1]) {
    let array = [
      "latitude",
      obj1.location?.coordinates[1],
      obj2.location?.coordinates[1],
    ];
    values.push(array);
  }
  if (obj1.craneDetails.type !== obj2.craneDetails.type) {
    let array = ["type", obj1.craneDetails.type, obj2.craneDetails.type];
    values.push(array);
  }
  if (obj1.craneDetails.model !== obj2.craneDetails.model) {
    let array = ["model", obj1.craneDetails.model, obj2.craneDetails.model];
    values.push(array);
  }
  if (obj1.craneDetails.height !== obj2.craneDetails.height) {
    let array = ["height", obj1.craneDetails.height, obj2.craneDetails.height];
    values.push(array);
  }
  if (
    obj1.craneDetails.boomColourScheme !== obj2.craneDetails.boomColourScheme
  ) {
    let array = [
      "boom colour scheme",
      obj1.craneDetails.boomColourScheme,
      obj2.craneDetails.boomColourScheme,
    ];
    values.push(array);
  }
  if (obj1.craneDetails.boomColours !== obj2.craneDetails.boomColours) {
    let array = [
      "boom colours",
      obj1.craneDetails.boomColours,
      obj2.craneDetails.boomColours,
    ];
    values.push(array);
  }
  if (obj1.craneDetails.craneLighting !== obj2.craneDetails.craneLighting) {
    let array = [
      "crane lighting",
      obj1.craneDetails.craneLighting,
      obj2.craneDetails.craneLighting,
    ];
    values.push(array);
  }
  if (
    obj1.locations[0]?.maximumGroundLevel !==
    obj2.locations[0]?.maximumGroundLevel
  ) {
    let array = [
      "maximum ground level",
      obj1.locations[0]?.maximumGroundLevel,
      obj2.locations[0]?.maximumGroundLevel,
    ];
    values.push(array);
  }
  if (
    obj1.locations[0]?.maximumOperatingHeightAGL !==
    obj2.locations[0]?.maximumOperatingHeightAGL
  ) {
    let array = [
      "maximum operating height AGL",
      obj1.locations[0]?.maximumOperatingHeightAGL,
      obj2.locations[0]?.maximumOperatingHeightAGL,
    ];
    values.push(array);
  }
  if (
    obj1.locations[0]?.maximumOperatingHeight !==
    obj2.locations[0]?.maximumOperatingHeight
  ) {
    let array = [
      "maximum operating height",
      obj1.locations[0]?.maximumOperatingHeight,
      obj2.locations[0]?.maximumOperatingHeight,
    ];
    values.push(array);
  }
  if (
    obj1.craneOperationParameters.operatingStartDate !==
    obj2.craneOperationParameters.operatingStartDate
  ) {
    let array = [
      "operating start date",
      obj1.craneOperationParameters.operatingStartDate,
      obj2.craneOperationParameters.operatingStartDate,
    ];
    values.push(array);
  }
  if (
    obj1.craneOperationParameters.operatingFinishDate !==
    obj2.craneOperationParameters.operatingFinishDate
  ) {
    let array = [
      "operating finish time",
      obj1.craneOperationParameters.operatingFinishDate,
      obj2.craneOperationParameters.operatingFinishDate,
    ];
    values.push(array);
  }
  if (
    obj1.craneOperationParameters.dailyStartTime !==
    obj2.craneOperationParameters.dailyStartTime
  ) {
    let array = [
      "daily start time",
      obj1.craneOperationParameters.dailyStartTime,
      obj2.craneOperationParameters.dailyStartTime,
    ];
    values.push(array);
  }
  if (
    obj1.craneOperationParameters.dailyFinishTime !==
    obj2.craneOperationParameters.dailyFinishTime
  ) {
    let array = [
      "daily finish time",
      obj1.craneOperationParameters.dailyFinishTime,
      obj2.craneOperationParameters.dailyFinishTime,
    ];
    values.push(array);
  }
  if (
    obj1.craneOperationParameters.craneOperatorName !==
    obj2.craneOperationParameters.craneOperatorName
  ) {
    let array = [
      "crane operator name",
      obj1.craneOperationParameters.craneOperatorName,
      obj2.craneOperationParameters.craneOperatorName,
    ];
    values.push(array);
  }
  if (
    obj1.craneOperationParameters.craneOperatorMobile !==
    obj2.craneOperationParameters.craneOperatorMobile
  ) {
    let array = [
      "crane operator mobile",
      obj1.craneOperationParameters.craneOperatorMobile,
      obj2.craneOperationParameters.craneOperatorMobile,
    ];
    values.push(array);
  }
  if (obj1.companyName !== obj2.companyName) {
    let array = ["company name", obj1.companyName, obj2.companyName];
    values.push(array);
  }
  if (obj1.legacy !== obj2.legacy) {
    let array = ["legacy", obj1.legacy, obj2.legacy];
    values.push(array);
  }
  if (obj1.addressWorksite !== obj2.addressWorksite) {
    let array = [
      "address of worksite",
      obj1.addressWorksite,
      obj2.addressWorksite,
    ];
    values.push(array);
  }
  if (obj1.crossStreet !== obj2.crossStreet) {
    let array = ["nearest cross street", obj1.crossStreet, obj2.crossStreet];
    values.push(array);
  }
  if (obj1.assessmentNumber !== obj2.assessmentNumber) {
    let message = "assessment number";
    if (!obj1.assessmentNumber) {
      let array = [message, "NULL", obj2.assessmentNumber?.toUpperCase()];

      values.push(array);
    }
    if (!obj2.assessmentNumber) {
      let array = [message, obj1.assessmentNumber?.toUpperCase(), "NULL"];

      values.push(array);
    }
    if (obj1.assessmentNumber && obj2.assessmentNumber) {
      let array = [
        message,
        obj1.assessmentNumber?.toUpperCase(),
        obj2.assessmentNumber?.toUpperCase(),
      ];
      values.push(array);
    }
  }
  return values;
}

export function ComputeClearance(
  ray: any,
  surfaceFeatures: {
    [id: number]: ISurfaceFeature;
  }
): any[][] {
  //---------------------Iterate thorugh surface Features and extract geometry --------------------------------------

  var Cesium = require("cesium");

  let surfaceFeaturesOLS = Object.entries(surfaceFeatures).filter((x) =>
    x[1].properties.name.includes("ols")
  );

  let filteredAndFormattedSurfacesArray = surfaceFeaturesOLS.map(
    (surface) => surface[1]
  );

  let surfaceArray: number[][][][][] = [];
  let surfaceArrayChunked: number[][] = [];

  for (
    let i = 0;
    i < Object.values(filteredAndFormattedSurfacesArray).length;
    i++
  ) {
    let z = Object.values(filteredAndFormattedSurfacesArray)[i].geometry
      .coordinates;
    surfaceArray.push(z);
  }

  let rawTriangleCoordsFlatConverted: number[][][][] = surfaceArray.map(
    (surface) =>
      surface.map((poly) =>
        poly.map((ring) =>
          ring.map((coordinate) =>
            Cesium.Cartesian3.fromDegrees(
              coordinate[0],
              coordinate[1],
              coordinate[2]
            )
          )
        )
      )
  );

  //---------------------Flatting Array of Cartesian Objects (x: ? y: ? z:? -> x, y, z ) --------------------------------------

  let SurfaceSetArray: number[][][] = [];
  let coordinateSet: number[][] = [];
  let individualArrays: number[] = [];

  rawTriangleCoordsFlatConverted.forEach((surface) => {
    surface.forEach((surfaceSet) => {
      surfaceSet.forEach((object) => {
        object.forEach((elements) => {
          individualArrays.push(Object.values(elements)[0]);
          individualArrays.push(Object.values(elements)[1]);
          individualArrays.push(Object.values(elements)[2]);
        });
        coordinateSet.push(individualArrays);
        individualArrays = [];
      });
    });
    SurfaceSetArray.push(coordinateSet);
    coordinateSet = [];
  });

  //-------------------Earcutting Flatted Coordinates(Cartesian) array --------------------------------------

  let triangleIndicies: number[][][] = [];
  let arrayTest: number[][] = [];

  SurfaceSetArray.forEach((surface) => {
    surface.forEach((surfaceSet) => {
      arrayTest.push(earcut(surfaceSet, undefined, 3));
    });
    triangleIndicies.push(arrayTest);
    arrayTest = [];
  });

  //--------------------------------Fetch coordinates from earcut results e.g.(3, 0, 1)---------------------------------------------------

  let arrayofCoordArrays: number[][] = [];
  let surfacesCoordArrays: number[][][] = [];
  let individualCoordArray: any[] = [];

  let count = 0;

  for (let i = 0; i < triangleIndicies.length; i++) {
    for (let x = 0; x < triangleIndicies[i].length; x++) {
      let triangle = triangleIndicies[i][x];

      for (let y = 0; y < triangle.length; y++) {
        individualCoordArray.push(
          rawTriangleCoordsFlatConverted[i][x][0][triangleIndicies[i][x][y]]
        );
      }
      arrayofCoordArrays.push(individualCoordArray);
      individualCoordArray = [];
    }
    surfacesCoordArrays.push(arrayofCoordArrays);
    arrayofCoordArrays = [];
  }

  //--------------------------------Run Intersection Tests against array of cartesian converted polygons ([])---------------------------------------------------

  let pointArray: any[][] = [];
  let pointPlusSurface: any[] = [];
  let surface = null;

  for (let i = 0; i < surfacesCoordArrays.length; i++) {
    for (let x = 0; x < surfacesCoordArrays[i].length; x++) {
      var intersection = Cesium.IntersectionTests.rayTriangle(
        ray,
        surfacesCoordArrays[i][x][0],
        surfacesCoordArrays[i][x][1],
        surfacesCoordArrays[i][x][2]
      );

      if (Cesium.defined(intersection)) {
        surface = surfaceFeaturesOLS[i][1].properties.name;

        let point = new Cesium.Cartographic.fromCartesian(intersection);
        pointPlusSurface.push(point);
        pointPlusSurface.push(surface);
        pointArray.push(pointPlusSurface);
      }
    }
  }
  return pointArray;
}

export function ComputeLowestIntersectionHeight(
  array: Cartesian3[],
  heightReference: number,
  isCranePoint: boolean
): number | null {
  let lowestClearnaceValue = 0;

  if (array === undefined) {
    return null;
  } else {
    for (let i = 0; i < array.length; i++) {
      if (array[i].z < lowestClearnaceValue || i === 0) {
        lowestClearnaceValue = Object.values(array[i])[2];
      }
    }

    if (isCranePoint) {
      return heightReference - lowestClearnaceValue;
    }
    return lowestClearnaceValue - heightReference;
  }
}

//-------------------------------------------------------- SET USER ACCESS CONTROL --------------------------------------------------------

export function SetAccessControl(
  userPermissions: IUserPermissions | undefined
) {
  let accessControl: IAccessControl = {
    active: false,
    global: false,
    airport: false,
    organisation: false,
    administrator: false,
    manager: false,
    readOnly: false,
  };
  if (userPermissions) {
    accessControl.active = userPermissions.active;
    accessControl.global = userPermissions.type === organisationType[0];
    accessControl.airport = userPermissions.type === organisationType[1];
    accessControl.organisation = userPermissions.type === organisationType[2];
    accessControl.administrator = userPermissions.administrator;
    accessControl.manager = userPermissions.manager;
    accessControl.readOnly = userPermissions.teamMember;
  }
  return accessControl;
}

export function GetOlsSurfaceResult(
  location: ICraneLocation,
  latestSurfaceSets: ISurfaceSet[]
) {
  return location.surfaceResults?.filter(
    (surfaceResult: any) =>
      surfaceResult.surfaceSetId === latestSurfaceSets[0].id
  )[0]!;
}
