import React, { lazy, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { GetUserToken, ShowLoading } from "../utils/functions";
import ProtectedRoute from "../middleware/ProtectedRoute";

interface AppRoutesProps { }
const NotFoundPage = lazy(() => import("../pages/ErrorPage/NotFoundPage/NotFoundPage"));
const UsersPage = lazy(() => import("../pages/UsersPage/UsersPage"));
const PaymentOutcomePage = lazy(() => import("../pages/PaymentOutcomePage/paymentOutcomePage"));
const PaymentCancelledPage = lazy(() => import("../pages/PaymentOutcomePage/paymentCancelledPage"));
const DraftConfirmationPage = lazy(() => import("../pages/DraftConfrmationPage/draftConfirmationPage"));
const CraneApplicationDetailPage = lazy(() => import("../pages/CraneApplicationDetailPage/CraneApplicationDetailPage"));
const ErrorAccountValidationPage = lazy(() => import("../pages/ErrorPage/Validation/ErrorAccountValidationPage"));
const ErrorPermissionPage = lazy(() => import("../pages/ErrorPage/Permission/ErrorPermissionPage"));
const ErrorPermissionPage_2 = lazy(() => import("../pages/ErrorPage/Permission/ErrorPermissionPage_2"));
const AirportsPage = lazy(() => import("../pages/AirportsPage/AirportsPage"));
const OrganisationsPage = lazy(() => import("../pages/OrganisationsPage/OrganisationsPage"));
const AddOrganisationPage = lazy(() => import("../pages/RegistrationPage/RegistrationPage"));
const AboutPage = lazy(() => import("../pages/AboutPage/AboutPage"));
const PlansPage = lazy(() => import("../pages/PlansPage/PlansPage"));
const AerodromesPage = lazy(() => import("../pages/AerodromesPage/AerodromesPage"));
const FeaturesToolsPage = lazy(() => import("../pages/FeaturesToolsPage/FeaturesToolsPage"));
const TermsCondtions = lazy(() => import("../pages/TermsCondtions/TermsCondtions"));
const PrivacyPage = lazy(() => import("../pages/PrivacyPage/PrivacyPage"));
const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
const ContactUsPage = lazy(() => import("../pages/ContactUsPage/ContactUsPage"));
const NewCraneApplicationPage = lazy(() => import("../pages/NewCraneApplicationPage/NewCraneApplicationPage"));
const UserProfilePage = lazy(() => import("../pages/UserProfilePage/UserProfilePage"));
const KMLPage = lazy(() => import("../pages/KmlsPage/ImportKMLfile"));
const HomePage = lazy(() => import("../pages/HomePage/HomePage"));
const LocationGuide = lazy(() => import("../pages/LocationGuidePage/LocationGuidePage"));
const NetworkErrorPage = lazy(() => import("../pages/ErrorPage/NetworkErrorPage/NetworkErrorPage"));
const InitialDevelopmentApplicationPage = lazy(() => import("../pages/InitialDevelopmentApplication/InitialDevelopmentApplicationPage"));
const HelpPage = lazy(() => import("../pages/HelpPage/HelpPage"));

// Session control variables
const MAX_SESSION_TIME = 60; // Minutes
let count = 0;

const AppRoutes: React.FC<AppRoutesProps> = ({ }) => {
	const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();
	//var location = useLocation();

	// Redirect user to Auth0 for authentication
	async function LogInUser() {
		await loginWithRedirect();
	}

	// Log out user and clean session and local storages
	async function LogOutUser() {
		sessionStorage.clear();
		localStorage.clear();
		logout({ returnTo: process.env.LOGOUT_URL });
	}

	// Calculates the time elapsed of the session
	function SessionTimeExpired(time: any) {
		var startDate = new Date(Number(time));
		var endDate = new Date();
		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		var minutes = seconds / 60;
		return minutes > MAX_SESSION_TIME;
	}

	//  Logs out user if he/she closes the tab or the browser
	if (!localStorage.isAuthenticated) {
		//LogOutUser();
	}

	// Log out the user if the SESSION_TIME expire
	if (localStorage.isAuthenticated && count === 0) {
		let date = localStorage.getItem("time");
		if (!date || SessionTimeExpired(date)) {
			LogOutUser().then(() => {
				setTimeout(() => {
					LogInUser();
				}, 200);
			});
		}
		count++;
	}

	// Show while waiting for Auth0 authentication
	if (isLoading) {
		return ShowLoading();
	}

	// Set variables for the session
	if (!localStorage.isAuthenticated) {
		localStorage.setItem("isAuthenticated", "true");
		localStorage.setItem("time", new Date().getTime().toString());
	}

	// Test Code : Session time left
	/*
	console.log(
		"Session time left: ",
		(MAX_SESSION_TIME - (Number(new Date().getTime()) - Number(localStorage.getItem("time"))) / 1000 / 60).toFixed(2) + " minutes"
	);
	*/

	// If user is registered in Auth0 but email was not verified
	if (isAuthenticated && !user?.email_verified) {
		return (
			<Switch>
				<Route path="/errorAccountValidation" exact={true} component={ErrorAccountValidationPage} />
				<Route path="/about" exact={true} component={AboutPage} />
				<Route path="/aerodromes" exact={true} component={AerodromesPage} />
				<Route path="/contact-us" exact={true} component={ContactUsPage} />
				<Route path="/features" exact={true} component={FeaturesToolsPage} />
				<Route path="/plans" exact={true} component={PlansPage} />
				<Route path="/privacy" exact={true} component={PrivacyPage} />
				<Route path="/help" exact={true} component={HelpPage} />
				<Route path="/termsconditions" exact={true} component={TermsCondtions} />
				<Redirect path="/home" exact={true} to="/" />;
				<Redirect to="/errorAccountValidation" />

			</Switch>
		);
	}

	// If user is authenticated by Auth0
	else if (isAuthenticated) {
		return (
			<Switch>
				<ProtectedRoute path="/airports" exact={true} component={AirportsPage} />
				<ProtectedRoute path="/organisations" exact={true} component={OrganisationsPage} />
				<ProtectedRoute path="/payment_outcome/:transactionId" exact component={PaymentOutcomePage} />
				<ProtectedRoute path="/payment_cancelled/:transactionId" exact component={PaymentCancelledPage} />
				<ProtectedRoute path="/draft_confirmation_page" exact component={DraftConfirmationPage} />
				<ProtectedRoute path="/registerAccount" exact={true} component={AddOrganisationPage} />
				<ProtectedRoute path="/kmls" exact={true} component={KMLPage} />
				<ProtectedRoute path="/crane" exact={true} component={NewCraneApplicationPage} />
				<ProtectedRoute path="/home" exact={true} component={HomePage} />
				<ProtectedRoute path="/locationguide" exact={true} component={LocationGuide} />
				<ProtectedRoute path="/users" exact={true} component={UsersPage} />
				<ProtectedRoute path="/profile" exact={true} component={UserProfilePage} />
				<ProtectedRoute path="/development" exact={true} component={InitialDevelopmentApplicationPage} />
				<ProtectedRoute path="/home/craneApplicationStatus/:craneApplicationId" exact component={CraneApplicationDetailPage} />
				<Route path="/errorPermission" exact={true} component={ErrorPermissionPage} />
				<Route path="/errorPermission/2" exact={true} component={ErrorPermissionPage_2} />
				<Route path="/networkError" exact={true} component={NetworkErrorPage} />
				<Route path="/about" exact={true} component={AboutPage} />
				<Route path="/aerodromes" exact={true} component={AerodromesPage} />
				<Route path="/contact-us" exact={true} component={ContactUsPage} />
				<Route path="/features" exact={true} component={FeaturesToolsPage} />
				<Route path="/plans" exact={true} component={PlansPage} />
				<Route path="/help" exact={true} component={HelpPage} />
				<Route path="/privacy" exact={true} component={PrivacyPage} />
				<Route path="/termsconditions" exact={true} component={TermsCondtions} />
				<Redirect path="/" exact={true} to="/home" />;
				<Route path="/" component={NotFoundPage} />

			</Switch>
		);
	}

	// If user is not authenticated by Auth0
	else if (!isAuthenticated) {
		return (
			<Switch>
				<Route path="/about" exact={true} component={AboutPage} />
				<Route path="/aerodromes" exact={true} component={AerodromesPage} />
				<Route path="/contact-us" exact={true} component={ContactUsPage} />
				<Route path="/features" exact={true} component={FeaturesToolsPage} />
				<Route path="/plans" exact={true} component={PlansPage} />
				<Route path="/help" exact={true} component={HelpPage} />
				<Route path="/privacy" exact={true} component={PrivacyPage} />
				<Route path="/termsconditions" exact={true} component={TermsCondtions} />
				<Redirect path="/home" exact={true} to="/" />;
				<Route path="/" exact={true} component={LandingPage} />
				<Redirect path="/errorPermission" exact={true} to="/home" />;
				<Route path="/networkError" exact={true} component={NetworkErrorPage} />
				<Route path="/" component={NotFoundPage} />

			</Switch>
		);
	} else {
		return null;
	}
};

export default AppRoutes;
